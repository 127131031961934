import PubNub from 'pubnub';
import StorageService from '../services/localstorage.service';
const uuid = PubNub.generateUUID();

export const pubnub = new PubNub({
    publishKey: process.env.PUBNUB_PUBLISH_KEY,
    subscribeKey: process.env.PUBNUB_SUBSCRIBE_KEY,
    uuid: uuid
});

export const addListener = (client, callback) => {
    client &&
    client.addListener({
        message: (messageEvent) => {
            callback && callback(messageEvent.message);
        }
    });
};

export const subscribe = (client, user) => {
    if (user) {
        const channel = user.id;
        client && pubnub.subscribe({ channels: [channel] });
    }
};

export const unsubscribe = (client) => {
    const user = StorageService.getItem('user');
    if (user) {
        const channel = user.id;
        client && pubnub.unsubscribe({ channels: [channel] });
    }
};
